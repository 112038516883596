.projects {
    max-width: 1000px;
    margin: 0 auto;
    padding: 50px 0px;
    margin-bottom: 30px;
  }
  
  .projects h2 {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 50px;
  }
  
  .projects ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .projects li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  .projects li:last-of-type {
    margin-bottom: 0;
  }
  
  .projects li a {
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    color: #333;
  }
  
  .projects li a:hover {
    color: #000000;
    transform: translateY(-5px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }
  
  .projects li p {
    text-align: center;
    color: #777;
    font-size: 1rem;
    margin-bottom: 10px;
    max-width: 400px;
  }
  
  .projects li img {
    width: 300px;
    height: 200px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .projects li img:hover {
    opacity: 0.7;
  }
  
  .project-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .project-modal img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  
  .project-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    font-size: 2rem;
    cursor: pointer;
  }
  
  .project-github {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 10px;
    color: #0077b6;
  }
  
  .project-github:hover {
    text-decoration: underline;
  }
  
  .projects li .project-info {
    flex: 1;
    text-align: center;
  }
  
  .projects li h3 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .projects li p {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .projects li a {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #000000;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .projects li a:hover {
    background-color: #ffffff;
  }