.about-section {
    background-color: #ffffff;
    padding: 100px 0;
  }
  
  .about-section h2 {
    font-size: 3rem;
    font-weight: 700;
    color: #1F2937;
    margin-bottom: 60px;
    align-items: center;
    text-align: center;
  }
  
  .about-section p {
    font-size: 1.5rem;
    color: #4B5563;
    line-height: 2.2rem;
    max-width: 700px;
    margin: 0 auto 60px;
  }
  
  .about-section .profile-img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    margin-bottom: 60px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  }

  .about-paragraph {
    padding: 0 20px; /* Or any other value you prefer */
  }
  
  /* profile pic CSS */
  .about-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }
  
  .about-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  