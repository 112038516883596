.tech-skills {
    padding: 60px 0;
    text-align: center;
    background: linear-gradient(to bottom right, #fe8c00, #f83600);
    border-radius: 100% 0 0 0;
  }
  
  .tech-skills h2 {
    font-size: 3rem;
    font-weight: 700;
    color: #1F2937;
    margin-bottom: 60px;
  }
  
  .tech-icons-container {
    margin: 0 auto;
    max-width: 960px; /* Set a maximum width for the icons container */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Set the grid to adjust to the available width */
    grid-gap: 1rem; /* Set a gap between the icons */
    justify-items: center; /* Center the grid items horizontally */
  }
  
  .tech-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .tech-icon img {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }
  
  @media only screen and (max-width: 768px) {
    .tech-icons-container {
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)); /* Change the grid column width on smaller screens */
    }
  }
  